import React, { useState, useEffect, useRef } from 'react';

function renderLoading() {
  return (
    <div style={{
      height: "8rem",
      backgroundColor: "white",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div className="spinner-border text-info" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default function LoadMore(props) {
  const load_func = props.onLoadMore
  const data_status = props.data_status
  const error = props.error
  const enabled = props.enabled

  if ( !enabled ) {
    return null
  }

  if (data_status === 'loading') {
    return renderLoading()
  }

  return (
    <div style={{
      height: "8rem",
      backgroundColor: "white",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <button type="button" className="btn btn-info btn-lg" onClick={load_func}>Load More ...</button>
    </div>
  )
}